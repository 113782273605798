import React, { useEffect } from "react";
import "./contact.scss";
import rally from "./../assets/files/Rally-ResearchProcess.pdf";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="contact">
        <div className="contact__wrapper">
          <h1>Contact Page Coming Soon</h1>
          <p>
            Download my resume
          </p>
          <a className="contact__button" href={rally} target="blank">
            Download
          </a>
        </div>
      </div>
      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            Created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Contact;
