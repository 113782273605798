import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import scrollDown from "../assets/images/scroll-down-rally.svg";
import { Link } from "react-router-dom";
import "./rally.scss";
import rallyVideo from "../assets/videos/RallyVideo2020.mp4";
import marketingPage from "../assets/images/Marketing-Page-updated.png";
import rallydots from "../assets/images/rally/rally-dots-4.png";
import rallyResearch from "./../assets/files/Rally-ResearchProcess.pdf";


const Rally = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <>
      <div className="rally__page-wrapper">
        <div className="rally__outline">
          <div className="rally__info-ctn">
            <div className="rally__info-ctn-inner">
              <span className="rally__title">Rally</span>
              <span className="rally__subtitle">Where Queers Meet</span>
            </div>
          </div>
          <button
            onClick={() => scrollTo()}
            to="scroll-to-element"
            className="rally__scroll-down"
          >
            <img
              className="rally__scroll-img"
              alt="scroll down"
              src={scrollDown}
            />
          </button>
        </div>
      </div>
      <Element name="scroll-to-element" className="rally__page-content">
        <div className="rally__video-def">
          <div className="rally__intro-ctn">
            <div className="rally__def-ctn">
              <span className="rally__rally">Rally:</span>
            </div>
            <span className="rally__intro">
              1. to muster for a common cause
            </span>
            <span className="rally__intro">
              2. to join together to renew an effort
            </span>
            <span className="rally__intro">3. to join in common cause</span>
          </div>
          <video
            controls
            playsinline
            autoplay
            muted
            loop
            className="rally__video"
          >
            <source
              src={rallyVideo}
              type="video/mp4"
              alt="Rally video walk-through prototype"
            />
          </video>
        </div>

        <div className="rally__dots-ctn">
          <div className="rally__dots-scroller">
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
          </div>
        </div>
      </Element>

      <div className="rally__page-content-2">
        <div className="rally__info-ctn-1">
          <span className="rally__rally">How It Works</span>
          <span className="rally__paragraph">
            Rally is a meet up app. Community members are encouraged to create
            events on the application, and use spaces that they have access to.
            Members of the app browse the events in all different categories,
            and find out important information like cost of the event, what communities the
            event is catering to, and information about the host.
          </span>

          <span className="rally__paragraph para-2">
            Read more about how Rally works in the marketing page below and
            watch the video prototype above.
          </span>
        </div>
        <div className="rally__marketing-ctn">
          <img
            alt="Rally"
            className="rally__marketing-img"
            src={marketingPage}
          />
        </div>
      </div>

      <div className="rally__page-content">
        <div className="rally__info-ctn-1">
        <div className="rally__dots-ctn">
          <div className="rally__dots-scroller">
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
          </div>
        </div>
          <div className="rally__why-rally-ctn">
            <span className="rally__rally">Why Rally? Why Now?</span>
            <span className="rally__paragraph">
              As queer spaces become harder to come by, Rally seeks to fill the
              gap and connect the community. It relies on community members to
              become the shepherds of their communities. Another key value of
              Rally is to connect queer people from all different backgrounds
              and ages, and allow them to socialize in a diverse ways. Rally
              seeks to create long-lasting and meaningful queer community.
              </span>
              <span className="rally__paragraph margin-top-2">
              As many folks in our communities
              have seen first hand, queer spaces everywhere are disappearing at
              an alarming rate. Rally is an app prototype that looks to help
              fill that void by putting the power to socialize back into the
              hands of queer people. It works just like any other meet-up
              platform but transparency and group-oriented hang outs are
              encouraged. As queer spaces disappear, queer communities are
              increasingly finding themselves looking for places to gather and
              connect.
            </span>
            <span className="rally__paragraph margin-top-2">
              Below are quotes pulled from the research interviews for this
              project.
            </span>
          </div>
        </div>

        <div class="card-container">
          <div class="row-itm circle-container">
            <div class="circle-container__hover">
              <p class="card-paragraph homepage-text--desktop-bold">Blendi</p>
              <p class="card-paragraph homepage-text--mobile">
                <span class="homepage-text--mobile-bold">Blendi -</span>
                "I'm realizing that [gay/queer bars] are all [cis] men"
              </p>
              <div class="circle-container__hover-overlay">
                <p class="hover-overlay-text">
                "I'm realizing that [gay/queer bars] are all [cis] men"
                </p>
              </div>
            </div>
          </div>
          <div class="row-itm circle-container">
            <div class="circle-container__hover">
              <p class="body-text-white card-paragraph homepage-text--desktop-bold">
                Nour
              </p>
              <p class="body-text-white card-paragraph homepage-text--mobile">
                <span class="homepage-text--mobile-bold">Nour -</span>
                “I'm interested in finding other queer Arabs. It's hard to find though.”
              </p>
              <div class="circle-container__hover-overlay">
                <p class="hover-overlay-text">
                “I'm interested in finding other queer Arabs. It's hard to find though.”
                </p>
              </div>
            </div>
          </div>
          <div class="row-itm circle-container">
            <div class="circle-container__hover">
              {/* <img src="/img/secure.svg" class="homepage-icons"/> */}
              <p class="body-text-white card-paragraph homepage-text--desktop-bold">
                Jess
              </p>
              {/* <p class="body-text-white card-paragraph homepage-text--desktop">Kepler takes your security seriously.</p> */}
              <p class="body-text-white card-paragraph homepage-text--mobile">
                <span class="homepage-text--mobile-bold">Jess -</span>
                "I want all of society to change but I think that happens first
                at the community level.”
              </p>
              <div class="circle-container__hover-overlay">
                {/* <img src="/img/secure.svg" class="homepage-icons--hover"/> */}
                <p class="hover-overlay-text">
                "I want all of society to change but I think that happens first
                at the community level.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rally__info-ctn-1">
        <div className="rally__why-rally-ctn">
        <span className="rally__rally">Rally Research &amp; Process</span>
        <span className="rally__paragraph margin-bottom-2">
              A lot more research and process work went into the making of Rally. 
              If you'd like to see interviews, distilled research results, 
              branding decisions/moodboards and user/demographic info, 
              download PDFs from the links below.
            </span>
        </div>
        <a className="rally__button" href={rallyResearch} target="blank">
          Download Process Overview
        </a>
        </div>

        <div className="rally__dots-ctn">
          <div className="rally__dots-scroller">
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
            <img className="rally__dots-divider" src={rallydots} alt="divder dots"/>
          </div>
        </div>
      </div>
      <div className="rally__page-content-2">
      <span className="rally__rally">Other Projects</span>
        <div className="rally__other-projects-ctn">
          
          <Link to='/kepler' className="rally__project-ctn-kepler">
                <div className="rally__project-info-ctn">
                    <span className="rally__project-title">Kepler</span>
                </div>
          </Link>
          <Link to='/communihelp' className="rally__project-ctn-communihelp">
                <div className="rally__project-info-ctn">
                    <span className="rally__project-title">Communihelp</span>
                </div>
          </Link>
          <Link to='/orca-quiz' className="rally__project-ctn-orca">
                <div className="rally__project-info-ctn">
                    <span className="rally__project-title">Which Orca Are You?</span>
                </div>
          </Link>
          <Link to='/other-projects' className="rally__project-ctn-other">
                <div className="rally__project-info-ctn">
                    <span className="rally__project-title">Other Projects</span>
                </div>
          </Link>

        </div>
      </div>
      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Rally;
