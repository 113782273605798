import React, { useEffect } from "react";
import "./orca.scss";
import { Link } from "react-router-dom";
import orca1 from "../assets/images/ORCA-1.svg";
import orca2 from "../assets/images/ORCA-2.svg";
import orca3 from "../assets/images/ORCA-3.svg";
import rallydots from "../assets/images/rally/rally-dots-4.png";

const Orca = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="orca__page-wrapper">
        <div className="orca__outline">
          <div className="orca__info-ctn">
            <div className="orca__info-ctn-inner">
              <span className="orca__title">Which Orca Are You?</span>
              <a
                className="orca__button"
                href="https://orcaquiz.netlify.app/"
                target="blank"
              >
                Take the Quiz
              </a>
              <span className="orca__paragraph">
                Which Orca Are You? is a React-based website that seeks to bring
                awareness to orca conservation. It is a personality test that
                asks a variety of lighthearted questions, and then based on
                responses matches users with a real orca on the west coast of
                Canada.
              </span>
            </div>
            <div className="orca__img-ctn">
              <img alt="orca whale 1" className="orca__img-1" src={orca1} />
              <img alt="orca whale 2" className="orca__img-2" src={orca2} />
              <img alt="orca whale 3" className="orca__img-3" src={orca3} />
            </div>
          </div>
        </div>
      </div>

      <div className="orca__page-content">
      <div className="orca__dots-ctn">
        <div className="orca__dots-scroller">
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
        </div>
      </div>
        <span className="orca__title margin-top-2">Other Projects</span>
        <div className="rally__other-projects-ctn">
          <Link to="/kepler" className="orca__project-ctn-kepler">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Kepler</span>
            </div>
          </Link>
          <Link to="/orca-quiz" className="orca__project-ctn-rally">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Rally</span>
            </div>
          </Link>
          <Link to="/communihelp" className="orca__project-ctn-communihelp">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Communihelp</span>
            </div>
          </Link>
          <Link to="/other-projects" className="orca__project-ctn-other">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Other Projects</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Orca;
