import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./navigation.scss";
import exit from "../assets/images/exit.svg";
import nav from "../assets/images/nav.svg";

const NavigationDropDown = styled.div`
  height: 85vh;
  width: 100vw;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  background: rgba(247, 229, 231, 0.96);
  top: -1rem;
  left: -1rem;
  transition: 0.6s;
  transition-timing-function: ease-in-out;
  transform: translateY(${({ isOpened }) => (isOpened ? 0 : "-100vh")});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
`;

const NavigationHumburger = styled.div`
  position: relative;
  z-index: 1001;
  transform: rotate(${({ isOpened }) => (isOpened ? 0 : 90)}deg);
  transition: 0.3s;

  &:hover {
    cursor: pointer;
  }
`;

const NavigationBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const Navigation = () => {
  const [open, setOpen] = useState(false);

  const navigationOpen = () => {
    setOpen(prevState => !prevState);
  };

  const navigationClose = () => {
    setOpen(false);
  };

  return (
    <div className="navigation">
      <NavigationHumburger isOpened={open} onClick={navigationOpen}>
        {open ? (
          <img alt="exit button" className="navigation__exit" src={exit} />
        ) : (
            <img alt="nav menu button" className="navigation__exit" src={nav} />
          )}{" "}
      </NavigationHumburger>
      <NavigationDropDown isOpened={open}>
        <nav className="navigation__menu">
          <ul>
            <li className="navigation__link">
              <Link onClick={navigationClose} to="/">
                Home
              </Link>
            </li>

            <li className="navigation__link-light">
              <Link onClick={navigationClose} to="/kepler">
                Kepler: On a mission to build the Internet in space
                  </Link>
            </li>
            <li className="navigation__link-light">
              <Link onClick={navigationClose} to="/rally">
                Rally: Where Queers Meet
                  </Link>
            </li>
            <li className="navigation__link-light">
              <Link onClick={navigationClose} to="/orca-quiz">
                Which Orca Are You?
                  </Link>
            </li>
            <li className="navigation__link-light">
              <Link onClick={navigationClose} to="/communihelp">
                CommuniHelp
                  </Link>
            </li>
            <li className="navigation__link-light">
              <Link onClick={navigationClose} to="/other-projects">
                Other Projects
                  </Link>
            </li>
            <li className="navigation__link">
              <Link onClick={navigationClose} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </NavigationDropDown>
      <NavigationBackground isOpen={open} onClick={navigationClose} />
    </div>
  );
};

export default Navigation;
