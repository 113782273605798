import React, { useEffect } from "react";
import "./kepler.scss";
import { Link } from "react-router-dom";
import rallydots from "../assets/images/rally/rally-dots-4.png";

const Kepler = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="kepler__page-wrapper">
        <div className="kepler__outline">
          <div className="kepler__info-ctn">
            <div className="kepler__info-ctn-inner">
              <span className="kepler__title">Kepler</span>
              <div className="kepler__btn-ctn">
                <a className="kepler__button" href="https://kepler.space/" target="blank">
                  Website
                </a>
                <a
                  className="kepler__button"
                  href="https://kepler-brand.space/"
                  target="blank"
                >
                  Brand Style Guide
                </a>
              </div>
              <span className="kepler__paragraph">
                Rebranding Kepler, a Toronto-based company whose mission it is
                to build the internet in space.
              </span>
              <span className="kepler__paragraph">
                Design and UI build by Heather Alsen
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="kepler__page-content">
      <div className="kepler__dots-ctn">
        <div className="kepler__dots-scroller">
          <img className="kepler__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="kepler__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="kepler__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="kepler__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="kepler__dots-divider" src={rallydots} alt="divder dots"/>
        </div>
      </div>
        <span className="kepler__title">Other Projects</span>
        <div className="rally__other-projects-ctn">
          <Link to="/rally" className="kepler__project-ctn-rally">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Rally</span>
            </div>
          </Link>
          <Link to="/communihelp" className="kepler__project-ctn-communihelp">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Communihelp</span>
            </div>
          </Link>
          <Link to="/orca-quiz" className="kepler__project-ctn-orca">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Which Orca Are You?</span>
            </div>
          </Link>
          <Link to="/other-projects" className="kepler__project-ctn-other">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Other Projects</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Kepler;
