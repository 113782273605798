import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./otherprojects.scss";

export class Otherprojects extends Component {
  render() {
    return (
      <div className="other-projects__page-wrapper">
        <div className="other-projects__card-container">
          <div className="other-projects__card">
            <div className="other-projects__card-details">
              <h2 className="other-projects__card-title">Neighbourhood</h2>
              <p className="other-projects__card-paragraph">
                Neighbourhood is a zine-like collection of interviews that I
                created for my thesis project in 2016. It focuses on issues of
                gender identity.
              </p>
              <Link to="/neighbourhood" className="other-projects__button">
                Check it out
              </Link>
            </div>
            <div className="other-projects__card-img-container">
              {/* <img className="other-projects__card-image" alt="kepler" src={neighbourhood}/> */}
            </div>
          </div>
          <div className="other-projects__card-ynot">
            <div className="other-projects__card-details">
              <h2 className="other-projects__card-title">YNOT</h2>
              <p className="other-projects__card-paragraph">
                A custom backback builder for a Toronto, based company that
                makes their premium products right in the city. Feel free to ask
                me about other work for YNOT such as lookbooks, advertising
                campagins, and t-shirt designs!
              </p>
              <a
                href="https://www.ynotmade.com/en/shop/magnetica/?sku=5016"
                className="other-projects__button"
              >
                Visit Builder
              </a>
            </div>
            <div className="other-projects__card-img-container">
              <div className="other-projects__card-img-wrapper">
                {/* <img className="other-projects__card-image" alt="rally" src={ynot}/> */}
              </div>
            </div>
          </div>
        </div>
        <div className="profile__footer">
          <div className="profile__footer-inner">
            <span className="profile__footer-txt">
              Created by Heather &amp; Josh 2020 &copy; all rights reserved
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Otherprojects;
