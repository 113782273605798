import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import LazyImage from "./LazyImage"
import "./neighbourhood.scss";

import n1 from "./../assets/images/other-projects/neighbourhood/n1.jpg";
import n2 from "./../assets/images/other-projects/neighbourhood/n2.jpg";
import n3 from "./../assets/images/other-projects/neighbourhood/n3.jpg";
import n4 from "./../assets/images/other-projects/neighbourhood/n4.jpg";
import n5 from "./../assets/images/other-projects/neighbourhood/n5.jpg";
import n6 from "./../assets/images/other-projects/neighbourhood/n6.jpg";
import n7 from "./../assets/images/other-projects/neighbourhood/n7.jpg";
import n8 from "./../assets/images/other-projects/neighbourhood/n8.jpg";
import n9 from "./../assets/images/other-projects/neighbourhood/n9.jpg";
import n10 from "./../assets/images/other-projects/neighbourhood/n10.jpg";
import n11 from "./../assets/images/other-projects/neighbourhood/n11.jpg";
import n12 from "./../assets/images/other-projects/neighbourhood/n12.jpg";
import n13 from "./../assets/images/other-projects/neighbourhood/n13.jpg";
import n14 from "./../assets/images/other-projects/neighbourhood/n14.jpg";
import n15 from "./../assets/images/other-projects/neighbourhood/n15.jpg";
import n16 from "./../assets/images/other-projects/neighbourhood/n16.jpg";
import n17 from "./../assets/images/other-projects/neighbourhood/n17.jpg";
import n18 from "./../assets/images/other-projects/neighbourhood/n18.jpg";
import n19 from "./../assets/images/other-projects/neighbourhood/n19.jpg";
import n20 from "./../assets/images/other-projects/neighbourhood/n20.jpg";
import n21 from "./../assets/images/other-projects/neighbourhood/n21.jpg";
import n22 from "./../assets/images/other-projects/neighbourhood/n22.jpg";
import n23 from "./../assets/images/other-projects/neighbourhood/n23.jpg";

const Neighbourhood = () => {
  return (
    <div className="neighbourhood">
      <div className="neighbourhood__container">
        <h1 className="neighbourhood__title">Neighbourhood</h1>
        <p className="neighbourhood__description">
         Neighbourhood is collection of interviews, originally printed as a large scale 11x17" zine.
         This project was completed as part of my thesis at Ryerson University. 
        </p>
        <p className="neighbourhood__description margin-bottom-2">
        Things to Note: All gender identities and pronouns are word-for-word what the individual expressed at the time of the interview. However, this project was completed in 2016. Since then 1 or more interviewees have 
         changed their pronouns and/or gender identity. Social handles on the credits of the final page are also out-of-date.
        </p>
        <div className="neighbourhood__image-container">
        {NEIGHBOORHOOD_IMAGES.map((image, index) => (
          <LazyImage
            key={index}
            src={image}
            alt={`neighbourhood ${index + 1}`}
          />
        ))}
        </div>
      </div>
    </div>
  );
};

export default Neighbourhood;

const NEIGHBOORHOOD_IMAGES = [
  n1,
  n2,
  n3,
  n4,
  n5,
  n6,
  n7,
  n8,
  n9,
  n10,
  n11,
  n12,
  n13,
  n14,
  n15,
  n16,
  n17,
  n18,
  n19,
  n20,
  n21,
  n22,
  n23
];
