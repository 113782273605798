import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { Link } from "react-router-dom";
import "./homepage.scss";
import heather from "../assets/images/heather-1.svg";
import dots from "../assets/images/dots-white.svg";
import hi from "../assets/images/hi-black.svg";
import scrollDown from "../assets/images/scroll-down.svg";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <>
      <div>
        <div className="hero-ctn no-padding">
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-1-1x.png"
            alt=""
            id="1024120843"
            className="hero-img hero-asset-1 rotate-1"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-1-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
            alt=""
            id="1658978430"
            className="hero-img hero-asset-2 rotate-2"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-3-1x.png"
            alt=""
            id="1526455246"
            className="hero-img hero-asset-3 rotate-3"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-4-1x.png"
            alt=""
            id="1749264195"
            className="hero-img hero-asset-4 rotate-4"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset+15.svg"
            alt=""
            id="1555633878"
            className="hero-img hero-asset-5 rotate-5"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/dots.svg"
            alt=""
            id="1289148333"
            className="hero-img hero-asset-6 rotate-6"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
          <img
            src="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/heather-2.svg"
            alt=""
            id="1393315587"
            className="hero-img hero-asset-7 rotate-7"
            data-dm-image-path="https://irp-cdn.multiscreensite.com/32b30542/dms3rep/multi/Asset-2-1x.png"
          />
        </div>
      </div>

      <div className="profile__ctn">
        <button
          onClick={() => scrollTo()}
          to="scroll-to-element"
          className="profile__scroll-down"
        >
          <img
            className="profile__scroll-img"
            alt="scroll down"
            src={scrollDown}
          />
        </button>

        <div className="profile__info-ctn">
          <Element name="scroll-to-element" className="profile__ctn-inner">
            <div className="profile__txt-ctn">
              <span className="profile__intro">
                I'm a digital designer based in Toronto, Canada.
              </span>
              <span className="tropiline-light profile__intro-2">
                I'm Heather. I have a passion for beautiful digital products. I
                pride myself for out-of-the-box thinking, and knowing just how
                far to push boundaries when it comes to design. Sometimes I
                dream in CSS.
                <Link to="/contact" className="button-mobile"  >
                <div className="profile__button">
                Contact Me
              </div>
                </Link>
              </span>
            </div>

            <div className="profile__intro-1">
              <div className="profile__profile-img-ctn">
                <img
                  alt="Heather Alsen Portrait"
                  className="profile__profile-img"
                  src={heather}
                />
                <div id="ease-out" className="profile__circle-animation">
                  <img alt="speach dots" className="profile__dots" src={dots} />
                  <img alt="speach text" className="profile__hi" src={hi} />
                </div>
              </div>
            </div>
          </Element>
        </div>
      </div>

      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            Created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Homepage;
