import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { Link } from "react-router-dom";
import "./communihelp.scss";
import scrollDown from "../assets/images/scroll-down-communihelp.svg";
import mobileScreens from "../assets/images/communihelp/mobile-screens.png";
import comm1 from "../assets/images/communihelp/comm0.jpg";
import comm2 from "../assets/images/communihelp/comm2.jpg";
import comm3 from "../assets/images/communihelp/comm3.jpg";
import comm4 from "../assets/images/communihelp/comm4.jpg";
import comm5 from "../assets/images/communihelp/comm5.jpg";
import communiHelpStyleGuide from "./../assets/files/CommuniHelp-Samples-StyleGuide.pdf";
import rallydots from "../assets/images/rally/rally-dots-4.png";

const CommuniHelpItem = ({ img, title, description, link }) => (
  <div className="communihelp__comm-ctn">
    <div className="communihelp__item-ctn">
      <img alt="welcome screen" src={img} className="communihelp__comm-img" />
      <div className="communihelp__txt-ctn">
        <span className="communihelp__subtitle">{title}</span>
        <span className="communihelp__paragraph">{description}</span>
        <a target="blank" href={link} className="communihelp__button">
          InVision Prototype
        </a>
      </div>
    </div>
  </div>
);

const Communihelp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <>
      <div className="communihelp__page-wrapper">
        <div className="communihelp__outline">
          <div className="communihelp__info-ctn">
            <div className="communihelp__info-ctn-inner">
              <span className="communihelp__title">CommuniHelp</span>
            </div>
          </div>
          <button
            onClick={() => scrollTo()}
            to="scroll-to-element"
            className="communihelp__scroll-down"
          >
            <img
              className="communihelp__scroll-img"
              alt="scroll down"
              src={scrollDown}
            />
          </button>
        </div>
      </div>

      <button
        onClick={() => scrollTo()}
        to="scroll-to-element"
        className="communihelp__scroll-down"
      >
        <img
          className="communihelp__scroll-img"
          alt="scroll down"
          src={scrollDown}
        />
      </button>

      <Element
        name="scroll-to-element"
        className="communihelp__page-content"
      ></Element>

      <div className="communihelp__page-content">
      <div className="orca__dots-ctn">
        <div className="orca__dots-scroller">
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
        </div>
      </div>
        <div className="communihelp__info-ctn-1">
          <span className="communihelp__communihelp">
            Matching Volunteers to Opportunities
          </span>
          <span className="communihelp__paragraph margin-bottom-2">
            CommuniHelp leverages technology to match volunteers with the right
            opportunities and organizations with the right volunteers.
          </span>

          <span className="communihelp__paragraph margin-bottom-2">
            On the volenteer side, CommuniHelp uses mobile application. Users
            imput pertinent information like location, length of the placement and 
            areas of interest. Once they have created their profile, they will
            be shown volunteer opportunities in the area, for which they can
            apply to via the app.
          </span>
        </div>
        <img
          className="communihelp__full-screen-img"
          src={mobileScreens}
          alt="mobile screens"
        />

        <div className="communihelp__info-ctn-1">
          <span className="communihelp__communihelp">
            Helping Organizations Find Volunteers
          </span>

          <span className="communihelp__paragraph margin-bottom-2">
            Organizations can also sign up to CommuniHelp via a web
            application. Most of my time at CommuniHelp was spent creating an
            organization-side web app prototype. Click the link below to view
            the prototype, or scroll down to check out some of the key user
            flows.
          </span>

          <a
            href="https://invis.io/FJO63AIE7NR"
            className="communihelp__button"
          >
            <span>InVision Prototype</span>
          </a>
        </div>
        <CommuniHelpItem
          img={comm1}
          title="Post a volunteer opportunity"
          description="After signing up, organizations create "
          link="https://projects.invisionapp.com/d/main#/console/15334923/323221399/preview"
        />
        <CommuniHelpItem
          img={comm2}
          title="Browse &amp; Recommended Applicants"
          description="When Browsing, organizations can see who stands out according to a number of traits."
          link="https://projects.invisionapp.com/d/main#/console/15334923/325869869/preview"
        />
        <CommuniHelpItem
          img={comm3}
          title="Manage Applications"
          description="On the page of the console, users can easily keep track of their applications."
          link="https://projects.invisionapp.com/d/main#/console/15334923/327521847/preview"
        />
        <CommuniHelpItem
          img={comm4}
          title="Message Applicants"
          description="Organizations have the ability to start a conversation with an applicant, full screen or condensed."
          link="https://projects.invisionapp.com/d/main#/console/15334923/332189909/preview"
        />
        <CommuniHelpItem
          img={comm5}
          title="Schedule an Interview"
          description="Speak with a candidate via messaging or invite them to apply. Schedule an interivew through the console."
          link="https://projects.invisionapp.com/d/main#/console/15334923/324854269/preview"
        />
        <a href={communiHelpStyleGuide} className="communihelp__button" target="blank">
          <span>Download Process</span>
        </a>
        <div className="orca__dots-ctn">
        <div className="orca__dots-scroller">
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
          <img className="orca__dots-divider" src={rallydots} alt="divder dots"/>
        </div>
      </div>
      </div>

      <div className="communihelp__page-content">
        <span className="communihelp__communihelp">Other Projects</span>
        <div className="rally__other-projects-ctn">
          <Link
            to="/kepler"
            className="communihelp__project-ctn-kepler"
          >
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Kepler</span>
            </div>
          </Link>
          <Link to="/rally" className="communihelp__project-ctn-rally">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Rally</span>
            </div>
          </Link>
          <Link to="/orca-quiz" className="communihelp__project-ctn-orca">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Which Orca Are You?</span>
            </div>
          </Link>
          <Link to="/other-projects" className="communihelp__project-ctn-other">
            <div className="rally__project-info-ctn">
              <span className="rally__project-title">Other Projects</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="profile__footer">
        <div className="profile__footer-inner">
          <span className="profile__footer-txt">
            created by Heather &amp; Josh 2020 &copy; all rights reserved
          </span>
        </div>
      </div>
    </>
  );
};

export default Communihelp;
