import React from 'react';
import './App.css';
import Navigation from "./components/navigation"
import Homepage from './components/homepage';
import Orca from './components/orca';
import Kepler from './components/kepler';
import Rally from './components/rally';
import OtherProjects from'./components/otherprojects';
import Communihelp from './components/communihelp';
import Neighbourhood from "./components/neighbourhood"
import Contact from "./components/contact"
import { BrowserRouter, Switch, Route } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/orca-quiz" component={Orca} />
        <Route path="/kepler" component={Kepler} />
        <Route path="/rally" component={Rally} />
        <Route path="/communihelp" component={Communihelp} />
        <Route path="/other-projects" component={OtherProjects} />
        <Route path="/contact" component={Contact} />
        <Route path="/neighbourhood" component={Neighbourhood} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
